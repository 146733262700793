.robotbg {
    background-image: url('../images/RobotBackground.jpg');
    background-size: contain;
    background-position: left;
    background-repeat: repeat;
    height: 50px;
  }
  .responsive-iframe {
    display:none;
  }
  
  @media (min-width: 640px) {
    .responsive-iframe {
      display:block;
      width: 960px;
      height: 660px;
    }
  }